
























































































































































































































































































































































































































































































































































































































































.el-tooltip__popper {
  max-width: 40% !important;
}
.el-dialog__body{
  padding: 0 !important;
}
.common-dialog {
  padding: 20px 60px 20px 20px;
  margin:10px;
  max-height: 50vh !important;
  overflow-y: scroll !important;
}
